<script setup lang="ts">
import { onMounted, watch, reactive, ref, Ref } from "vue";
import { useUserStore } from "../../stores/user";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { shortDateWithTimeTz } from "../../utils/dateUtils";
// @ts-ignore
import { useRoute, useRouter, type RouteLocationNormalized } from 'vue-router/auto';
import EditDeleteRenderer from "../../components/common/aggrid/EditDeleteRenderer.vue";
import UserNameAndEmailRenderer from "../../components/user/NameAndEmailRenderer.vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import { useThemeStore } from "../../stores/themeStore";
import { connectToServerApi } from "../../utils/agGridUtils";
import { ColDef, GridApi, GridReadyEvent, GridOptions, RowClassParams } from 'ag-grid-community';

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const USERS_URL = '/users';
const advancedFilterKeys: string[] = ["id", "name", "email", "tenant.name", "active", "created_at"];
const themeStore = useThemeStore();

const gridApi: Ref<GridApi | null> = ref(null);

interface LocalState {
  page: number;
  searchText: string;
  filters: Filter[] | null;
  advancedFilterKeys: string[];
  sort: string;
  page_size: number;
}

interface Filter {
  column: string;
  condition: string;
  value: string;
}

interface Permission {
  text: string;
  value: string;
}

const userStore = useUserStore();
const route: RouteLocationNormalized = useRoute();
const router = useRouter();
const showFilters: Ref<boolean> = ref(false);
let context = ref({});
let USERS_EXTERNAL_FILTER_KEY = "userFilters";

  const permissions = [
  { text: "Access Inbound Uploader", value: "permissions.access_inbound_uploader:true" },
  { text: "Access Outbound Uploader", value: "permissions.access_outbound_uploader:true" },
  { text: "Adjust Inbound Order", value: "permissions.adjust_inbound_order:true" },
  { text: "Adjust Lp", value: "permissions.adjust_lp:true" },
  { text: "Adjust Outbound Order", value: "permissions.adjust_outbound_order:true" },
  { text: "Cancel Inbound Order", value: "permissions.cancel_inbound_order:true" },
  { text: "Cancel Outbound Order", value: "permissions.cancel_outbound_order:true" },
  { text: "Create Lp Label", value: "permissions.create_lp_label:true" },
  { text: "Create Pallet Label", value: "permissions.create_pallet_label:true" },
  { text: "Create Warehouse", value: "permissions.create_warehouse:true" },
  { text: "Delete User Account", value: "permissions.delete_user_account:true" },
  { text: "Delete Warehouse", value: "permissions.delete_warehouse:true" },
  { text: "Manage Billings", value: "permissions.edit_billing:true" }, // TODO: Backend throws 500
  { text: "Support Ticket Admin", value: "permissions.support_ticket_role:admin" },
  { text: "Support Ticket SuperAdmin", value: "permissions.support_ticket_role:superadmin" },
  { text: "Support Ticket User", value: "permissions.support_ticket_role:user" },
  { text: "Update Lp", value: "permissions.update_lp:true" },
  { text: "Update User Account", value: "permissions.update_user_account:true" },
  { text: "Update User Permissions", value: "permissions.update_user_permissions:true" },
  { text: "Update Warehouse", value: "permissions.update_warehouse:true" },
];

const selectedPerms: Ref<Filter[]> = ref([]);

const defaultState: LocalState = {
  page: parseInt(route.query.page as string) || 1,
  searchText: "",
  filters: [],
  advancedFilterKeys: [],
  sort: "id",
  page_size: 100
};

const localState: LocalState = reactive(
  JSON.parse(localStorage.getItem("userFilters") || JSON.stringify(defaultState))
);

const onGridReady = (params: GridReadyEvent) => {
  gridApi.value = params.api;
  addFuncsToColDefs();

  if (gridApi.value) {
    context.value = {
      apiUrl: USERS_URL,
      filterKey: USERS_EXTERNAL_FILTER_KEY,
      advancedFilterKeys: advancedFilterKeys
    }
    gridApi.value.setGridOption("serverSideDatasource", connectToServerApi());

    // Apply default filter for active users
    const defaultFilter = {
      active: {
        type: "set",
        values: ["1"],
        filterType: 'set',
      }
    };
    gridApi.value.setFilterModel(defaultFilter);
    gridApi.value.onFilterChanged();
  }
};

onMounted(async () => {
  setPageTitle("Manage Users");
  mapStoredValueInModel();
});

const storedShowFilters = localStorage.getItem("showFilters");
showFilters.value = storedShowFilters != null ? JSON.parse(storedShowFilters) : false;

watch(showFilters, () => {
  localStorage.setItem("showFilters", JSON.stringify(showFilters.value));
});

watch(localState, () => {
  localStorage.setItem("userFilters", JSON.stringify(localState));
});

const clearAllFilters = () => {
  selectedPerms.value = [];
  if (gridApi.value) {
    gridApi.value.setFilterModel({
      active: {
        type: "set",
        values: ["1"],
        filterType: 'set',
      }
    });
    gridApi.value.onFilterChanged();
  }
};

const mapStoredValueInModel = () => {
  const savedState = localStorage.getItem("userFilters");
  if (savedState) {
    const parsedState = JSON.parse(savedState);
    if (parsedState.filters) {
      selectedPerms.value = parsedState.filters.filter(
        (filter: { column: string }) => filter.column !== 'name'
      );
    }
  }
};

const defaultColDef: Ref<ColDef> = ref({
  sortable: true,
  floatingFilter: true,
  suppressMenu: true,
  resizable: true,
  width: 120,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
});

const gridOptions: GridOptions = {
  rowModelType: "serverSide",
  rowSelection: "multiple",
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 50,
  },
  rowHeight: 70,
  getRowStyle: (params: RowClassParams): { [key: string]: string } | undefined => {
    if (params.data?.active === false) {
      return {
        'color': 'red',
        "margin-left": '10px',
        "margin-right": '10px'
      };
    }
    return {
      "margin-left": '10px',
      "margin-right": '10px'
    };
  }
};

const valueGetters = {
  active: (params: any) => params.data?.active === true ? 'Active' : 'Disabled',
  created_at: (params: any) => shortDateWithTimeTz(params.data?.created_at)
};

const dateFilterParams = {
  filterOptions: ["equals", "greaterThanOrEqual", "lessThanOrEqual", "inRange", "blank"],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};

const textFilterParams = {
  filterOptions: ["contains", "equals", "blank", "notEqual"],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};

const colDefs = ref([
  {
    field: "id",
    headerName: "Id",
    cellDataType: "number",
    width: 70,
    filter: "agNumberColumnFilter",
  },
  {
    field: "name",
    headerName: "User",
    cellDataType: "text",
    width: 150,
    filter: "agTextColumnFilter",
    cellRenderer: UserNameAndEmailRenderer,
    filterParams: textFilterParams,
  },
  {
    field: "email",
    headerName: "Email",
    cellDataType: "text",
    width: 100,
    hide: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "tenant.name",
    headerName: "Tenant",
    cellDataType: "text",
    width: 135,
    filter: "agTextColumnFilter",
      filterParams: textFilterParams,
  },
  {
  field: "active",
  headerName: "Status",
  cellDataType: "text",
  width: 135,
  valueGetter: (params: any) => params.data?.active === true ? 'Active' : 'Disabled',
  filter: 'agSetColumnFilter',
  filterParams: {
      values: ['Active', 'Disabled'],
      defaultToNothingSelected: false,
      defaultValue: ['Active'],
      valueFormatter: (params: any) => params.value === 'Active' ? 'Active' : 'Disabled',
      keyCreator: (params: any) => params.value === 'Active' ? '1' : '0'
    },
  },
  {
    field: "created_at",
    headerName: "Created At",
    cellDataType: "text",
    width: 135,
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
  },
  {
    headerName: "Actions",
    width: 50,
    cellRenderer: EditDeleteRenderer,
    cellRendererParams: {
      editUrl: `/users`,
      showEdit:true
    }
  }
]);

const onRowDoubleClicked = (event: any) => {
  const viewUrl = `/users/${event.data.id}`;
  router.push(viewUrl);
};

const addFuncsToColDefs = () => {
  for (let def of colDefs.value) {
    let colName = def.field;
    if (colName && valueGetters[colName as keyof typeof valueGetters]) {
      def['valueGetter'] = valueGetters[colName as keyof typeof valueGetters];
    }
  }
};

addFuncsToColDefs();

const setPermissionFilters = () => {
  localState.filters = [...selectedPerms.value];
  gridApi.value?.onFilterChanged();
}
</script>

<template>
  <div class="mb-6">
    <div class="d-flex justify-space-between w-100 px-4 align-center">
      <div>
        <v-card-title class="d-flex justify-space-between flex-wrap lg:flex-nowrap">
          <span>{{ pageTitle }}</span>
        </v-card-title>
        <v-card-subtitle>Use this section to manage users. </v-card-subtitle>
      </div>
      <v-btn
        color="primary"
        to="/users/new"
        v-if="userStore.hasPermissionTo('create_user_account')"
        >New User</v-btn
      >
    </div>
  </div>
  <div class="ml-4 mr-4">
    <div class="d-flex mb-4">
      <v-btn
        color="blue"
        size="small"
        variant="outlined"
        @click="showFilters = !showFilters"
        class="filter-btn ml-2 mt-1 mr-2"
        :append-icon="showFilters ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
        >{{ showFilters ? "Hide Filters" : "Show Filters" }}</v-btn
      >
    </div>

    <div class="d-flex">
      <v-card v-if="showFilters" flat class="filter-card">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-select
              class="pa-3 mt-6"
              label="Filter by Permission"
              v-model="selectedPerms"
              :items="permissions"
              variant="outlined"
              density="compact"
              item-title="text"
              item-value="value"
              chips
              multiple
              closable-chips
            />
          </v-col>
        </v-row>

        <div class="d-flex justify-end">
          <v-btn
            :disabled="selectedPerms.length === 0"
            color="#f44336"
            append-icon="mdi-close-circle"
            class="mr-4 mb-3 mt-0 clear"
            @click="clearAllFilters"
            >Clear All</v-btn
          >
          <v-btn color="primary" @click="setPermissionFilters" class="mr-4 mb-3 mt-0"
            >Apply</v-btn
          >
        </div>
      </v-card>

      <AgGridVue
        style="height: 1000px; width: 1500px"
        :class="themeStore.agTheme"
        :columnDefs="colDefs"
        :gridOptions="gridOptions"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        @rowDoubleClicked="onRowDoubleClicked"
        :context="context"
      >
      </AgGridVue>
    </div>
  </div>
</template>

<style scoped>
.v-select {
  width: 200%;
}

.filter-card {
  width: 50%;
  height: 100%;
  margin-right: 20px;
}

.filter-card >>> .v-label {
  font-size: 14px !important;
  margin-top: -7px;
}
</style>
