<template>
    <div class="d-flex flex-column">
        <div class="d-flex mr-4">
            <v-avatar :image="gravatarUrl(props.params.data.email, { size: 32, default: 'retro' })" size="32"
                class="mr-2 mt-2"></v-avatar>
            <div>{{ props.params.data.name }}</div>
        </div>
        <div class="text-caption text-indigo-lighten-2">{{props.params.data.email}}</div>
    </div>


</template>

<script setup>

import gravatarUrl from "gravatar-url";

const props = defineProps({
    params: Object
});

</script>
